/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/normalize.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./src/css/global.css"