// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buero-geschichte-js": () => import("./../../../src/pages/buero/geschichte.js" /* webpackChunkName: "component---src-pages-buero-geschichte-js" */),
  "component---src-pages-buero-js": () => import("./../../../src/pages/buero.js" /* webpackChunkName: "component---src-pages-buero-js" */),
  "component---src-pages-buero-mitarbeiter-js": () => import("./../../../src/pages/buero/mitarbeiter.js" /* webpackChunkName: "component---src-pages-buero-mitarbeiter-js" */),
  "component---src-pages-buero-partner-js": () => import("./../../../src/pages/buero/partner.js" /* webpackChunkName: "component---src-pages-buero-partner-js" */),
  "component---src-pages-buero-philosophie-js": () => import("./../../../src/pages/buero/philosophie.js" /* webpackChunkName: "component---src-pages-buero-philosophie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-datenschutz-js": () => import("./../../../src/pages/kontakt/datenschutz.js" /* webpackChunkName: "component---src-pages-kontakt-datenschutz-js" */),
  "component---src-pages-kontakt-impressum-js": () => import("./../../../src/pages/kontakt/impressum.js" /* webpackChunkName: "component---src-pages-kontakt-impressum-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-employee-js": () => import("./../../../src/templates/employee.js" /* webpackChunkName: "component---src-templates-employee-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-partner-geschichte-js": () => import("./../../../src/templates/partner-geschichte.js" /* webpackChunkName: "component---src-templates-partner-geschichte-js" */),
  "component---src-templates-projecttypes-js": () => import("./../../../src/templates/projecttypes.js" /* webpackChunkName: "component---src-templates-projecttypes-js" */),
  "component---src-templates-projekt-js": () => import("./../../../src/templates/projekt.js" /* webpackChunkName: "component---src-templates-projekt-js" */)
}

